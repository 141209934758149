$(document).ready(function() {
  var pathArray = window.location.pathname.split('/');
  var lang = pathArray[1] ? pathArray[1] : 'ru';
  i18next.init({
    //"debug": true,
    "lng": lang,
    "fallbackLng": false,
    "keySeparator": false,
    "nsSeparator": false,
    "resources": {
      "en": {
        "translation": {
          "Обязательное поле": "Required field",
          "Минимальная длинна строки {{count}} символа": "Minimum length of name is {{count}} symbols",
          "Максимальная длинна строки {{count}} символов": "Maximum length of name is {{count}} symbols",
          "Введите корректный e-mail": "Use correct e-mail",
          "Введите корректный номер телефона": "Use correct phone number"
        }
      },
      "uk": {
        "translation": {
          "января": "January",
          "февраля": "February",
          "марта": "March",
          "апреля": "April",
          "мая": "May",
          "июня": "June",
          "июля": "July",
          "августа": "August",
          "сентября": "September",
          "октября": "October",
          "ноября": "November",
          "декабря": "December",
          "с": "з",
          "Обязательное поле": "Обов'язкове поле",
          "Минимальная длинна строки {{count}} символа": "Мінімальна довжина рядка {{count}} символи",
          "Максимальная длинна строки {{count}} символов": "Максимальна довжина рядка {{count}} символи",
          "Введите корректный e-mail": "Введіть коректний e-mail",
          "Введите корректный номер телефона": "Введіть коректний номер телефону"
        }
      }
    }
  });
});