$(document).ready(function() {
  $('a[href*=\\#]').bind("click", function (e) {
    e.preventDefault();
  });
  $('a[href*=\\#].scroll').bind("click", function (e) {
    var top = $($(this).attr('href')).offset().top - 60;
    e.preventDefault();
    $('html, body').stop().animate({scrollTop: top}, 1000);
  });

  $('#header-nav').on('click', 'a:not([data-toggle="dropdown"])', function () {
    $('#header-nav').collapse('hide');
  });

  if ($(document).height() <= $(window).height()) {
    $(".footer").addClass("navbar-fixed-bottom");
  }

  $(window).on('resize', function() {
    $(".footer").removeClass("navbar-fixed-bottom");
    if ($(document).height() <= $(window).height()) {
      $(".footer").addClass("navbar-fixed-bottom");
    }
  });

  function randomElement(arr) {
    return arr[Math.floor((Math.random()*arr.length))];
  }

  var bgs = [];
  $('.home-bg').each(function(){
    bgs.push('#' + $(this).attr('id'));
  });

  setTimeout(function(){
    $(bgs[0]).addClass('active');
  }, 1000);

  setInterval(function(){
    $('.home-bg').each(function(){
      $(this).removeClass('active');
    });
    setTimeout(function(){
      $(randomElement(bgs)).addClass('active');
    }, 600);
  }, 7000);

  $('.home-portfolio-toggle').on('click', function(){
    if(!$('#home-portfolio').hasClass('in')){
      $('#home-portfolio').collapse('show');
      setTimeout(function(){
        $('#home').addClass('home-portfolio-open');
        var top = $('#home-portfolio').offset().top - 300;
        $('html, body').stop().animate({scrollTop: top}, 300);
      }, 150);
    } else {
      $('html, body').stop().animate({scrollTop: 0}, 300);
      $('#home-portfolio').collapse('hide');
      $('#home').removeClass('home-portfolio-open');
    }
  });

  $('.feedback-toggle').on('click', function(){
    $('body').addClass('feedback-open');
    alterFeedbackBlockPosition('#feedback', 80);
    setTimeout(function(){
      $('.feedback-inner').addClass('active');
    }, 100);
  });

  $('.home-portfolio-block-close').on('click', function(){
    $('html, body').stop().animate({scrollTop: 0}, 300);
    $('#home-portfolio').collapse('hide');
    $('#home').removeClass('home-portfolio-open');
  });

  $('.feedback-block-close').on('click', function(){
    $('.feedback-inner').removeClass('active');
    setTimeout(function(){
      $('body').removeClass('feedback-open');
    }, 300);
  });

  function alterFeedbackBlockPosition(block, offset){
    var block_h = $(block).find('.feedback-block').height() + offset;
    var window_h = $(window).height();
    $(block).removeClass('feedback-fixed-top');
    if (block_h > window_h) {
      $(block).addClass('feedback-fixed-top');
    }
  }

  $('.project-gallery-block').not('.slick-initialized').slick({
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    dots: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false
        }
      }
    ]
  });

  function youtube_parser(url){
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match && match[2].length == 11) ? match[2] : false;
  }

  $('iframe').each(function(){
    var iframe = $(this);
    var url = $(iframe).attr('src');

    if(url.indexOf('youtu') + 1) {
      var video_id = youtube_parser(url);
      if (video_id) {
        var new_iframe = '<iframe src="https://www.youtube.com/embed/' + video_id + '?rel=0&amp;showinfo=0"' +
        'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
        $(iframe).parent().html(
          '<div class="project-video video-iframe">' + new_iframe + '</div>'
        );
      }
    }
  });

  function getIpinfo(){
    return $.get("https://ipinfo.io?token=2f6c0e302d4989", function () {}, "jsonp");
  }

  function setCountry(country) {
    if (country != ''){
      Cookies.set('country', country, {expires: 1});
    }
  }

  var country = Cookies.get('country');
  if(!country) {
    getIpinfo().always(function (resp) {
      country = (resp && resp.country) ? resp.country : "";
      setCountry(country);
    });
  }

  $('input[type="tel"]').intlTelInput({
    // allowDropdown: false,
    // autoHideDialCode: false,
    // autoPlaceholder: "off",
    // dropdownContainer: "body",
    // excludeCountries: ["us"],
    // formatOnDisplay: false,
    geoIpLookup: function(callback) {
      if(!country) {
        getIpinfo().always(function (resp) {
          country = (resp && resp.country) ? resp.country : "";
          setCountry(country);
          callback(country);
        });
      }
      else{
        callback(country);
      }
    },
    // hiddenInput: "full_number",
    initialCountry: "auto",
    nationalMode: false,
    // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
    // placeholderNumberType: "MOBILE",
    //preferredCountries: ['ua', 'ru', 'nl', 'ca', 'ae'],
    // separateDialCode: true,
    utilsScript: "/js/intlTelInput/utils.js"
  });

  $.validator.methods.phone = function( value, element ) {
    return this.optional( element ) || $(element).intlTelInput("isValidNumber");
  };

  $('.feedback-form').each(function(){
    $(this).validate({
      rules: {
        name: {
          required: true,
          minlength: 2,
          maxlength: 100
        },
        phone: {
          required: true,
          phone: true
        },
        email: {
          required: true,
          email: true
        },
        message: {
          minlength: 2,
          maxlength: 1000
        }
      },
      messages: {
        name: {
          required: i18next.t("Обязательное поле"),
          minlength: i18next.t("Минимальная длинна строки {{count}} символа", {count: '2'}),
          maxlength: i18next.t("Максимальная длинна строки {{count}} символов", {count: '100'})
        },
        phone: {
          required: i18next.t("Обязательное поле"),
          phone: i18next.t("Введите корректный номер телефона")
        },
        email: {
          required: i18next.t("Обязательное поле"),
          email: i18next.t("Введите корректный e-mail")
        },
        message: {
          minlength: i18next.t("Минимальная длинна строки {{count}} символа", {count: '2'}),
          maxlength: i18next.t("Максимальная длинна строки {{count}} символов", {count: '1000'})
        }
      }
    });
  });

});